import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    nodeMap: new Map(),
    formItemMap: new Map(),
    isEdit: null,
    loginUser: JSON.parse(localStorage.getItem("loginUser") || "{}"),
    // 初始发放化购物车数据
    sendTickCarList:JSON.parse(localStorage.getItem("tickList"))|| [],
    selectedNode: {},
    selectFormItem: null,
    design: {},
  },
  mutations: {
    //g更新购物车
    setTickCarList(state, payload) {
      // 更新购物车数据
      state.sendTickCarList = payload;
    
      // 同步到本地存储(数据缓存)
      localStorage.setItem('tickList',JSON.stringify(payload))
      //console.log("检查存购物车的是不是json``"+JSON.parse(localStorage.getItem("tickList")))
        //uni.setStorageSync('sendCartList', payload)
    },
    	//清空购物车
	clearTickCartList(state) {
	  // 更新购物车数据
	  state.sendTickCarList = []
   // localStorage.setItem('tickList',[])
   localStorage.removeItem('tickList')
	
	},
    selectedNode(state, val) {
      state.selectedNode = val;
    },
    loadForm(state, val) {
      state.design = val;
    },
    setIsEdit(state, val) {
      state.isEdit = val;
    },
  },
  getters: {
    selectTickCount(state, getters) {
      let sum = 0;
      state.sendTickCarList.forEach((item) => {
        // 累加 数量
      //  console.log("算一下"+item.carListbyUser.length)
        sum += item.carListbyUser.length;
      });
      // 记得返回计算结果
      return sum;
    },
  },
  actions: {},
  modules: {},
});
