import Vue from "vue";
import axios from "axios";
import { MessageBox, Message } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import uniWebview from '@dcloudio/uni-webview-js'
Vue.prototype.$axios = axios;
//const baseURL = Vue.prototype.BASE_URL;
//const baseURL = 'http://ops.ekaquan.cn/';
//const baseURL = 'http://company.ekaquan.cn/java/';
// const baseURL = 'https://backend.ekaquan.cn/';//测试地址
const baseURL = 'https://backend.ekaquan.com/';//正式地址
//const baseURL = 'http://39.98.247.61:9500/';

const service = axios.create({
  baseURL: baseURL,
  timeout: 50000
});

service.defaults.withCredentials = true; // 让ajax携带cookie
service.interceptors.request.use(
  // 每次请求都自动携带Cookie
  config => {


    // console.log("参数要token了"+localStorage.getItem('wflow-token'))
    config.headers.Authorization = "Bearer " + localStorage.getItem('wflow-token')

    return config;
  },
  // eslint-disable-next-line handle-callback-err
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  rsp => {
    //console.log("接口返回" + JSON.stringify(rsp.data.code))
    if (rsp.data.code == '401' || rsp.data.code == '11015') {
      //alert("过期")
      MessageBox.alert('登录过期或账号信息变化，请重新登录', '登录过期', {
        confirmButtonText: '确定',
        callback: action => {
          //console.log("确定")
          //alert("开始传数据sss")
          uniWebview.postMessage({
            data: { name: "重新登录", flag: "goLogin" }
          })
          uniWebview.navigateBack({
            delta: 1
          });
        },
        customClass: 'myBClass'
      });
    }
    //	MessageBox.alert("登陆失效，请点击右上角头像重新切换人员····需要改成返回小程序并且退出重新登录小程序");
    // uni.reLaunch({
    // 				url: '/pages/login/login'
    // 			  });

    return rsp;
  },
  // 拦截异常的响应
  err => {
    switch (err.response.status) {
      case 401:
        //alert("错误")
        console.log("登录过期了···")
        MessageBox.alert('登录过期了，请重新登录', '登录过期', {
          confirmButtonText: '确定',
          callback: action => {
            //console.log("确定")
            //	alert("开始传数据sss")
            uniWebview.postMessage({
              data: { name: "重新登录", flag: "goLogin" }
            })
            uniWebview.navigateBack({
              delta: 1
            });
          },
          customClass: 'myBClass'
        });

        break;
      case 403:
        Message.warning("抱歉，您无权访问！")
        break;
      case 500:
        break;
      case 404:
        break;
      default:
        //throw 'request error'
        return Promise.reject(err);
    }
    //throw 'request error'
    return Promise.reject(err);
  }
);

export function syncRequest(config) {
  //console.log("你的基础路径" + baseURL)
  let ajax = new XMLHttpRequest();
  ajax.withCredentials = true
  //ajax.responseType = 'json'

  ajax.open(config.method, baseURL + config.url, false);
  ajax.onload = function (e) {
    if (ajax.readyState === 4) {
      if (ajax.status === 200 && config.success) {
        config.success(ajax.responseText)
      } else if (config.fail) {
        config.fail(ajax.responseText)
      }
    }
  };
  ajax.onerror = function (e) {
    if (config.fail) {
      config.fail(ajax.responseText)
    }
  }
  if (config.data) {
    if (config.type === 'json') {
      ajax.setRequestHeader("Content-type", "application/json");
      ajax.send(JSON.stringify(config.data))
    } else {
      ajax.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      let form = new FormData()
      for (const key in config.data) {
        form.set(key, config.data[key])
      }
      ajax.send(form)
    }
  } else {
    ajax.send(null)
  }
}

export default service;
